.App {
  text-align: center;
}

.text-green-500 {
  color: rgb(16, 185, 129);
}

.text-red-500 {
  color: rgb(239, 68, 68);
}

.space-y-5 > * + * {
  margin-top: 1.25rem;
}
